.preview {
  display: flex;
  gap: 12px;
  width: 75vw;
  flex-direction: column;
}

.imagebox {
  position: relative;
  width: 100%;
}

.imagebox img {
  width: 100%;
}

.editbutton {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.editbutton:hover {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
}

.actions {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  position: relative;
}

.actions button {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 28px;
  border: 1px solid var(--ui-border-color);
  color: var(--primary-text-color);
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px;
}

.actions button:hover {
  background-color: var(--primary-background-hover-color);
}

.actions button:first-child {
  margin-right: 16px;
}

.actions input {
  padding-right: 64px !important;
}
