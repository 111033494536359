.capturescreen {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dialog-background-color);
  box-shadow: 0px 6px 20px -2px rgba(var(--theme-box-shadow), 0.2);
}

.previewbox {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.previewbox img {
}

.previewbox .imagebox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 75%;
}

.previewbox .imagebox img {
  width: 100%;
}

.actions {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  width: 75%;
  position: relative;
}

.actions button {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 28px;
  border: 1px solid var(--ui-border-color);
  color: var(--primary-text-color);
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px;
}

.actions button:hover {
  background-color: var(--primary-background-hover-color);
}

.actions button:first-child {
  margin-right: 16px;
}

.actions input {
  padding-right: 64px !important;
}

.editButton {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 12px;
  cursor: pointer;
}

.editButton:hover {
  color: white;
}
