.edit-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#filerobot-image-editor-root {
  width: 100%;
  height: 100%;
}

#filerobot-image-editor-root {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0 !important;
}

#filerobot-image-editor-root > div {
  border-radius: 0 !important;
}

#filerobot-image-editor-root
  #filerobot-image-editor-modal
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child
  > div:nth-child(2) {
  right: 8px;
  left: auto;
}

span[title="Close window"] {
  display: none;
}

.edit-image button {
  text-transform: none;
  display: inline-block;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 1.5;
  min-width: 62px;
  height: 32px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  border: 0;
  color: #f9fafb;
  background-color: #6879eb;
  border-color: transparent;
}

.save-image {
  position: fixed;
  top: 6px;
  right: 12px;
  z-index: 10000;
}
