.toolbar {
  /* background-color: #000; */
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  color: var(--primary-text-color);
}

.toolbar > button {
  border: none;
  background-color: var(--primary-background-color);
  border: 1px solid var(--layout-border-color);
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.toolbar > button:hover {
  background-color: var(--primary-background-hover-color);
}

.toolbar > button svg {
  height: 40px;
  width: 40px;
}

.toolbar > button div {
  height: 100%;
}

.toolbar > button div:first-child {
  flex: 0 0 32px;
}

.toolbar > button div:nth-child(2) {
  padding-left: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.toolbar > button div:nth-child(2) span {
  display: flex;
  color: var(--primary-text-color);
}

.toolbar > button div:nth-child(2) .title {
  font-size: 20px;
  margin-bottom: 8px;
}

.popup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  background-color: #597bfc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  color: white;
  font-weight: 600;
}

a {
  color: var(--link-color);
}
