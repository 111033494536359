body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: var(--primary-text-color);
}

.App {
  height: calc(100vh - 40px);
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.chip {
  display: inline-flex;
  background-color: #f6eefb;
  border: 1px solid #a25ddc;
  color: #a25ddc;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
}

h2 {
  color: var(--primary-text-color);
}

p {
  line-height: 1.75;
  color: var(--primary-text-color);
}

.help {
  display: block;
  width: 100%;
  padding: 32px;
  border-top: 1px solid #c5c7d0;
  overflow-y: auto;
  flex: 1 1 100%;
}

.privacy {
  padding: 24px;
}
