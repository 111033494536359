.closebutton {
  height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
  padding: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}

.closebutton svg {
  height: 16px !important;
  width: 16px !important;
}
