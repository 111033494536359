.recordcamera {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dialog-background-color);
  box-shadow: 0px 6px 20px -2px rgba(var(--theme-box-shadow), 0.2);
  flex-direction: column;
}

.preview {
  width: 75vw;
}

.actions {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button:first-child {
  margin-right: 16px;
}

.button {
  height: 28px;
  border: 1px solid var(--ui-border-color);
  color: var(--primary-text-color);
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px;
}

.button:hover {
  background-color: var(--primary-background-hover-color);
}
